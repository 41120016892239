
<template>
  <div class="app-container">
      <div class="main-body">
        <el-row class="call-title">
          <el-col :span="24">
            <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
            <span class="title">处置证信息</span>
          </el-col>
        </el-row>
        <div ref="searchBar" class="form-cls">
          <el-form @heightChange="_getTableHeight()" size="small">
            <el-row>
              <el-col :span="6">
                <el-form-item label="项目名称:">
                  <el-input
                    v-model.trim="queryParams.projectName"
                    clearable
                    type="text"
                    class="input-cls input-width-6"
                    placeholder="请输入内容"
                    @keyup.enter.native="queryList"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="项目简称:">
                  <el-input
                    v-model.trim="queryParams.abbreviation"
                    clearable
                    type="text"
                    class="input-cls input-width-6"
                    placeholder="请输入内容"
                    @keyup.enter.native="queryList"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="施工单位:">
                  <el-input
                    v-model.trim="queryParams.constructProjedt"
                    clearable
                    type="text"
                    class="input-cls input-width-6"
                    placeholder="请输入内容"
                    @keyup.enter.native="queryList"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item>
                  <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- </search-bar> -->
        </div>
        <div class="main-body">
          <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
            <template #buttons>
              <el-upload
              :action="`${uploadUrl}?type=0`"
              :data="uploadData"
              :headers="uploadHeaders"
              :on-success="uploadFileSuccess"
              :on-error="uploadFileError"
              :show-file-list="false"
              :file-list="[]"
            >
              <el-button plain key="addFile" size="small" type="primary"> 新增导入 </el-button>
            </el-upload>

            <el-upload
              style="margin-left: 10px"
              :data="uploadData"
              :headers="uploadHeaders"
              :on-success="uploadFileSuccess"
              :on-error="uploadFileError"
              :action="`${uploadUrl}?type=1`"
              :show-file-list="false"
              :file-list="[]"
            >
              <el-button plain key="addUpdate" size="small" type="primary"> 导入更新 </el-button>
            </el-upload>

            <el-button plain style="margin-left: 10px" key="exportFileTemp2" size="small" @click="exportFileTemp2" type="primary">下载导入模板</el-button>

            </template>
            <template #tools>
              <vxe-pager
                align="left"
                placement="top"
                :total="total"
                :current-page.sync="queryParams.currentPage"
                border
                :page-sizes="[100, 120, 150]"
                size="small"
                :page-size.sync="queryParams.pageSize"
                :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="queryList('0')"
              />
            </template>
          </vxe-toolbar>
        </div>
         <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="sortable-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            show-footer
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            show-overflow="tooltip"
            show-footer-overflow="tooltip"
            show-header-overflow="tooltip"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column type="seq" title="序号" width="50" align="center" />
            <vxe-table-column field="projectName" title="项目名称"  width="300" align="center"  />
            <vxe-table-column field="abbreviation" title="项目简称"  width="150" align="center" />
            <vxe-table-column field="permitNumber" title="施工许可证" width="150"  align="center"/>
            <vxe-table-column field="planCaseNumber" title="处理方案备案编号" width="150"  align="center"/>
            <vxe-table-column field="__fleetName1" title="运输单位1" width="200"  align="center"/>
            <vxe-table-column field="__fleetName2" title="运输单位2" width="200"  align="center"/>
            <vxe-table-column field="__fleetName3" title="运输单位3" width="200"  align="center"/>
            <vxe-table-column field="__fleetName4" title="运输单位4" width="200"  align="center"/>
            <vxe-table-column field="__fleetName5" title="运输单位5" width="200"  align="center"/>
            <vxe-table-column field="__fleetName6" title="运输单位6" width="200"  align="center"/>
            <vxe-table-column field="__recycleProject1" title="处置单位1"  width="150"   align="center"/>
            <vxe-table-column field="__recycleProject1_longitude_latitude" title="处置单位1经纬度"  width="200"  align="center"/>
            <vxe-table-column field="__recycleProject2" title="处置单位2"  width="150"  align="center"/>
            <vxe-table-column field="__recycleProject2_longitude_latitude" title="处置单位2经纬度"  width="200"  align="center"/>
            <vxe-table-column field="__recycleProject3" title="处置单位3"  width="150"  align="center"/>
            <vxe-table-column field="__recycleProject3_longitude_latitude" title="处置单位3经纬度"  width="200"  align="center"/>
            <vxe-table-column field="effectiveDate" title="生效日期"  width="120"  align="center"/>
            <vxe-table-column field="expireDate" title="截止日期"  width="120"  align="center"/>
            <vxe-table-column align="center" fixed="right" title="操作" width="120">
              <template v-slot="{ row }">
                <span style="color: deepskyblue; cursor: pointer" @click="handleCarView(row)">车辆明细</span>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-col>
      </el-row>
    </div>


    <el-drawer
      :title="carViewTitile"
      :visible.sync="carViewVisible"
      size="1100px"
      direction="rtl">
      <div class="car-view-container">
        <el-form size="small">
          <el-row>
            <el-col :span="8">
              <el-form-item label="车牌号码:">
                <el-input
                  v-model.trim="queryCarView.carPlate"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryCarViewList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="运输单位:">
                <el-input
                  v-model.trim="queryCarView.fleetName"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryCarViewList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryCarViewList"> 查询 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 表单结束 -->
        <div class="operation-btn">
          <el-upload
            :action="`${carUploadUrl}?type=0`"
            :data="carUploadData"
            :headers="uploadHeaders"
            :on-success="uploadFileSuccess2"
            :on-error="uploadFileError"
            :show-file-list="false"
            :file-list="[]"
          >
            <el-button plain key="addFile" size="small" type="primary"> 新增导入 </el-button>
          </el-upload>
          <el-upload
            style="margin-left: 10px"
            :data="carUploadData"
            :headers="uploadHeaders"
            :on-success="uploadFileSuccess2"
            :on-error="uploadFileError"
            :action="`${carUploadUrl}?type=1`"
            :show-file-list="false"
            :file-list="[]"
          >
            <el-button plain key="addUpdate" size="small" type="primary"> 导入更新 </el-button>
          </el-upload>
          <el-button plain style="margin-left: 10px" key="addFile1" size="small" @click="exportFileTemp" type="primary">下载导入模板</el-button>
          <el-button plain style="margin-left: 10px" key="addFile2" size="small" @click="exportFile" type="primary">导出</el-button>
        </div>
        <!-- 操作按钮结束 -->
          <vxe-table
            :data="carViewList"
            fit
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column type="seq" title="序号" width="60" align="center" />
            <vxe-table-column field="carPlate" title="车牌号码" width="150" align="center" />
            <vxe-table-column field="fleetName" title="运输单位"  width="200" align="center" />
            <vxe-table-column field="carType" title="车辆类型" width="150"  align="center"/>
            <vxe-table-column field="carColor" title="车牌颜色" width="130"  align="center"/>
            <vxe-table-column field="totalWeight" title="总质量" width="120"  align="center"/>
            <vxe-table-column field="verfiedWeight" title="核定载重量" width="120"  align="center"/>
            <vxe-table-column field="weight" title="可装载吨位" width="120"  align="center"/>
        </vxe-table>

        <div class="car-view-pager">
          <vxe-pager
            align="left"
            placement="top"
            :total="carTotal"
            :current-page.sync="queryCarView.currentPage"
            border
            :page-sizes="[100, 120, 150]"
            size="small"
            :page-size.sync="queryCarView.pageSize"
            :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
            @page-change="queryCarViewList('0')"
          />
        </div>
      </div>
    </el-drawer>


  </div>
</template>

<script>
import { mapState } from "vuex";
import CalTableHeight from '@/mixins/calTableHeight'
import { 
  baseDisposeList as queryList,
  baseDisposeDownloadTemplate as downloadTemplate2,
  carLicenseList as queryCarViewList, 
  carLicenseUpload,
  carLicenseDownload, 
  carLicenseDownloadTemplate as downloadTemplate } from '@/api/base'
import { getToken } from '@/utils/auth.js'
import downloadFile  from '@/utils/downloadFile'
const projectLicenseId = '' // 1822824477337513985
export default {
  data() {
    return {
      total: 0,
      carTotal: 0,
      uploadUrl: '/admin-gov/projectLicense/upload', // admin-gov
      carUploadUrl: '/admin-gov/carLicense/upload', // admin-gov
      uploadData: {},
      carUploadData: {
        projectLicenseId
      },
      uploadHeaders:{
        Authorization: `Bearer ${getToken()}` // 让每个请求携带自定义token 请根据实际情况自行修改
      },
      queryParams: {
        projectName: '',
        constructProjedt: '', // 施工单位
        abbreviation: '',
        currentPage: 1,
        pageSize: 100
      },
      queryCarView: {
        carPlate: '',
        fleetName: '',
        projectLicenseId,
        currentPage: 1,
        pageSize: 100
      },
      carViewTitile: '',
      dataList: [],
      currentData: {},
      listLoading: false,
      // 表单
      formData: {
        name: '',
        type: [],
        radius: '',
        lat: '',
        log: ''
      },
      carViewList: [],
      carViewVisible: false
    };
  },
  mixins: [CalTableHeight],
  computed: {
    ...mapState("navBar", {
      refreshUrl: (state) => state.refreshUrl,
    }),
  },
  async mounted() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      this.$refs.table.connect(this.$refs.xToolbar)
    })
    this.$nextTick(() => {
      this._getTableHeight()
    })
    this.queryList()

    if(projectLicenseId){
      this.queryCarViewList()
      this.carViewVisible = true
    }
  },
  methods:{
    uploadFileSuccess() {
      this.$message.success(`上传成功`)
    },
    uploadFileSuccess2() {
      this.$message.success(`上传成功`)
      this.queryCarViewList()
    },
    uploadFileError(err) {
      const res = JSON.parse(err.message)
      this.$message.error( res.message || `上传失败`)
    },
    queryList(){
      this.listLoading = true
      return queryList(this.queryParams)
        .then(({ data }) => {
          this.total = data.total
          this.dataList = data.records.map((ele)=>{
            const {fleetList, recycleProjectList } = ele
            let res = {}
            let k = 0
            while(k < 6){
              const fleet = fleetList[k] || {}
              const recycle = recycleProjectList[k] || {}
              res[`__fleetName${k + 1}`] = fleet.name || '--'
              res[`__recycleProject${k + 1}`] = recycle.businessUnit || '--'
              res[`__recycleProject${k + 1}_longitude_latitude`] = `${recycle.longitude || ''}  ${recycle.latitude || ''}`
              k++
            }
            return {
              ...ele,
              ...res,
            }
          })
          console.log(this.dataList)
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    queryCarViewList(){
      return queryCarViewList({
        ...this.queryCarView,
      })
      .then(({ data }) => {
        this.carTotal = data.total
        this.carViewList = data.records
      })
    },
    handleCarView(row){
      this.currentData = row
      this.queryCarView.carPlate = ''
      this.queryCarView.fleetName = ''
      this.queryCarView.projectLicenseId = row.id
      this.carUploadData.projectLicenseId = row.id
      this.queryCarView.currentPage = 1
      this.carViewTitile = `车辆明细 - ${row.planCaseNumber}`
      this.queryCarViewList()
      this.carViewVisible = true
    },
    exportFileTemp(){
      return downloadTemplate({
      }).then(res=>{
        downloadFile(res)
      })
    },
    exportFileTemp2(){
      return downloadTemplate2({
      }).then(res=>{
        downloadFile(res)
      })
    },
    exportFile(){
      const { queryCarView } = this
      carLicenseDownload({
        ...queryCarView
      }).then(res => {
        downloadFile(res)
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}
.icon-cls-green {
  color: #67c23a;
}
.dialog-row {
  margin-top: 10px;
}
.car-view-container{
  padding: 0 20px;
}
.operation-btn{
  display: flex;
  margin-bottom: 20px;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.amap-section{
  height:600px
}
.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}
.el-pagination {
  float: right;
}
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}
.vxe-table--footer .col-blue {
  background: #f2f5f0;
}
/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}
.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}
.write-color {
  border-color: #f0f0f0;
}
/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}
.input-width-7 {
  width: 70% !important;
}
.input-width-8 {
  width: 80% !important;
}
.input-width-16{
  width: 300px
}
</style>
